import { checkAdemMicroAppStatus } from './computeRolePermission';
import { setGoogleMapApiKey } from './helpers/googleMap/loadGoogleMap';
import { clearSessionStorage } from './sessionStorageKeys';
import { addRoutesPrefixForAIOPSFreeIfNeeded } from './saseViews/utils/addRoutesPrefixForAIOPSFreeIfNeeded';

clearSessionStorage();

export default async ({ sparkyFramework, vars }) => {
  // eslint-disable-next-line no-undef
  const envName = (vars ?? {}).env_name || process.env.ENV_NAME;
  const { pdf_mode } = sparkyFramework.getFrameworkVars();
  const logStyle =
    'color:#fa582d; background:#141414; font-size:0.85rem; padding:0.5rem; margin: 0.15rem auto; font-family: Rockwell; border: 2px solid #fa582d; border-radius: 4px;font-weight: bold; text-shadow: 1px 1px 1px #141414;';
  console.log(`%c adem ui env: ${envName} 🌏`, logStyle);
  // eslint-disable-next-line no-undef
  console.log(`%c adem ui version: ${process.env.APP_VERSION} 📦`, logStyle);

  console.log(`%c is pdf_mode: ${!!pdf_mode} 📦`, logStyle);

  if (envName === 'staging') {
    // eslint-disable-next-line no-undef
    console.info(
      '%c release changelog 🚀: ',
      logStyle,
      `https://prisma-access.docs.pan.run/sase-adem-group/dem-ui/changelog.html`
    );
  }

  setGoogleMapApiKey(
    // eslint-disable-next-line no-undef
    vars?.google_map_api_key || process.env.GOOGLE_MAP_API_KEY
  );

  return async ({ auth }) => {
    const { setUpManager } = await import('./index.utils');
    console.log('TenantInfo', auth?.tsg_id, vars);

    const setUpResponse = await setUpManager(sparkyFramework, auth, vars);

    const { cosmosFeatures } =
      sparkyFramework.getState()?.saseIAMainState ?? {};

    const isFeatureEnabled = (featureName, defaultValue) => {
      if (!cosmosFeatures?.length) {
        return Boolean(defaultValue);
      }
      return cosmosFeatures
        .find((data) => data.feature === featureName)
        ?.permission?.includes('read');
    };

    /**
     * TODO: uncomment the below lines after April 10th.
     * Since the GA date for Activity Insights and Netsec Visibility is on April 10th.
     * So for now we still need the Monitor - Users page to always be present
     */
    const frameworkVars = sparkyFramework.getFrameworkVars();
    const { private_site_origin } = frameworkVars;
    const isPrivateApp = Boolean(private_site_origin);

    const isAdemMicroAppEnabled = checkAdemMicroAppStatus(auth);
    const navigation = [
      {
        key: 'dem_rum_views',
        title: 'Hidden RUM View',
        path: '/dem/dem-rum-views',
        noRBACheck: true,
        children: [
          {
            key: 'adem-rum-app-detail-view',
            title: 'RUM App Detail View',
            path: '/dem/dem-rum-views/app-detail-dashboard',
            exact: false,
            noRBACheck: true,
            hidden: true,
            component: () =>
              import('./saseViews/AppDetailView/ExposedAppDetailView'),
          },
          {
            key: 'adem-rum-user-details-view',
            title: 'RUM User Details View',
            path: '/dem/dem-rum-views/user-details-dashboard',
            exact: false,
            noRBACheck: true,
            hidden: true,
            component: () =>
              import('./saseViews/UserDetailsView/ExposedUserDetailsView'),
          },
          {
            key: 'adem-rum-new-test',
            title: 'ADEM App New Test',
            path: '/dem/dem-rum-views/new-test',
            exact: true,
            noRBACheck: true,
            hidden: true,
            component: () =>
              import('./saseViews/AppTestFormView/ExposedAppTestFormView'),
          },
        ],
      },
      {
        key: 'settings',
        title: 'Settings',
        path: '/settings',
        noRBACheck: true,
        expandable: true,
        mergeNav: true,
        children: [
          {
            key: 'dem-settings',
            title: setUpResponse.isSettingsVisible
              ? 'Access Experience Management'
              : 'Application Experience',
            contentClassName: 'dem-settings',
            path: '/settings/dem-settings',
            exact: true,
            noRBACheck: true,
            hidden:
              !isFeatureEnabled(
                'panw_sase:settings_system_application',
                true
              ) && !setUpResponse.isSettingsVisible,
            component: () =>
              import('./saseViews/SettingsView/SettingsViewWrapper'),
          },
        ],
      },
      {
        key: 'monitor',
        title: 'Monitor',
        path: '/monitor',
        noRBACheck: true,
        expandable: true,
        mergeNav: true,
        children: [
          {
            key: 'monitor-palocations',
            title: 'Prisma Access Locations',
            contentClassName: 'monitor-palocations',
            path: '/monitor/palocations',
            exact: false,
            noRBACheck: true,
            component: () =>
              import(
                './saseViews/PrismaLocationView/PrismaLocationViewWrapper'
              ),
            avail: () => isFeatureEnabled('panw_sase:pa_location', true),
          },
          // {
          //   key: 'monitor-ngfw-sites',
          //   title: 'Ngfw Sites',
          //   contentClassName: 'monitor-ngfw-sites',
          //   path: '/monitor/ngfw-sites',
          //   exact: false,
          //   noRBACheck: true,
          //   component: () =>
          //     import('./saseViews/NgfwSitesView/NgfwSitesViewWrapper'),
          // },
          {
            key: 'monitor-users',
            title: 'Users',
            contentClassName: 'monitor-users',
            path: '/monitor/users',
            exact: false,
            noRBACheck: true,
            component: () => import('./saseViews/UsersView/UsersViewWrapper'),
            avail: () =>
              isPrivateApp && isFeatureEnabled('panw_sase:users', true),
          },
          {
            key: 'monitor-dem-application',
            title: 'Prisma Access Dem Application',
            contentClassName: 'monitor-dem-application',
            path: '/monitor/applications/dem-application',
            exact: false,
            noRBACheck: true,
            hidden: true,
            // AppDetailsViewWrapper contains:
            // 1. App Experience Details
            // 2. Manage App Tests
            // 3. Edit/New App Test
            component: () =>
              import('./saseViews/appsView/AppDetailsViewWrapper'),
          },
          {
            key: 'monitor-remote-sites',
            title: 'Prisma Access Site Details',
            contentClassName: 'monitor-site-details',
            path: '/dem/dem-remote-sites/details',
            exact: true,
            hidden: true,
            noRBACheck: true,
            component: () =>
              import(
                './saseViews/sitesView/SiteDetails/SiteDetailsViewWrapper'
              ),
          },
          {
            key: 'monitor-remote-sites-ngfw',
            title: 'NGFW Site Details',
            contentClassName: 'monitor-ngfw-site-details',
            path: '/monitor/branch-sites/ngfwSites/details/experience',
            exact: true,
            hidden: true,
            noRBACheck: true,
            component: () =>
              import('./saseViews/NgfwSitesView/NgfwSitesViewWrapper'),
          },
        ],
      },
      {
        key: 'insights',
        path: '/insights',
        noRBACheck: true,
        mergeNav: true,
        expandable: true,
        children: [
          {
            key: 'adem-rum-application-experience-view',
            title: 'Application Experience',
            path: '/dem/dem-rum-views/application-experience',
            exact: false,
            noRBACheck: true,
            component: () =>
              import(
                './saseViews/ApplicationExperienceView/ExposedApplicationExperienceView'
              ),
          },
        ],
      },
      {
        key: 'adem_export_user',
        title: 'Hidden Users',
        path: addRoutesPrefixForAIOPSFreeIfNeeded(
          '/insights/activity_insights_user/users'
        ),
        noRBACheck: true,
        children: [
          {
            key: 'adem-export-user-details',
            title: 'Users',
            contentClassName: 'monitor-users',
            path: addRoutesPrefixForAIOPSFreeIfNeeded(
              '/insights/activity_insights_user/users'
            ),
            exact: false,
            noRBACheck: true,
            hidden: true,
            component: () =>
              import('./saseViews/UsersView/UsersViewDetailsWrapper'),
          },
        ],
      },
      {
        key: 'adem_export_app',
        title: 'Hidden App',
        path: addRoutesPrefixForAIOPSFreeIfNeeded(
          '/insights/activity_insights_app/apps'
        ),
        noRBACheck: true,
        children: [
          {
            key: 'adem-export-app-details',
            title: 'Prisma Access Dem Application',
            contentClassName: 'monitor-dem-application',
            path: addRoutesPrefixForAIOPSFreeIfNeeded(
              '/insights/activity_insights_app/apps'
            ),
            exact: false,
            noRBACheck: true,
            hidden: true,
            // AppDetailsViewWrapper contains:
            // 1. App Experience Details
            // 2. Manage App Tests
            // 3. Edit/New App Test
            component: () =>
              import('./saseViews/appsView/AppDetailsViewWrapper'),
          },
        ],
      },
      {
        key: 'dem_pdf_views',
        title: 'Hidden PDF View',
        path: '/dem/dem-pdf-views',
        noRBACheck: true,
        children: [
          {
            key: 'adem-pdf-app-activity-view',
            title: 'ADEM PDF App Activity View',
            contentClassName: 'sparky-light-only',
            path: '/dem/dem-pdf-views/app-detail',
            exact: false,
            noRBACheck: true,
            hidden: true,
            avail: () => !!pdf_mode, // default to true for testing purpose, accessible only in pdf mode
            component: () =>
              import(
                './sasePdfViews/AppActivityPdfView/ExposedAppActivityPdfView'
              ),
          },
          {
            key: 'adem-pdf-user-activity-view',
            title: 'ADEM PDF User Activity View',
            contentClassName: 'sparky-light-only',
            path: '/dem/dem-pdf-views/user-detail',
            exact: false,
            noRBACheck: true,
            hidden: true,
            avail: () => !!pdf_mode, // default to true for testing purpose, accessible only in pdf mode
            component: () =>
              import('./sasePdfViews/UserDetailsPdfView/UserDetailsPdfView'),
          },
        ],
      },
    ];

    return {
      navigation: () =>
        isAdemMicroAppEnabled || isPrivateApp ? navigation : null,
      exposes: {
        default: {
          DemSummaryViewWrapper: () =>
            import('./saseViews/SummaryView/SummaryViewWrapper'),
          DemSettingsViewWrapper: () =>
            import('./saseViews/SettingsView/SettingsViewWrapper'),
          PrismaLocationViewWrapper: () =>
            import('./saseViews/PrismaLocationView/PrismaLocationViewWrapper'),
          ApplicationsViewWrapper: () =>
            import('./saseViews/appsView/AppsViewWrapper'),
          DemDeviceExpCard: () =>
            import('./saseViews/components/deviceExpCard/index'),
          IncidentExperienceTrend: () =>
            import('./saseViews/incidentsView/IncidentExperienceTrendWrapper'),
          SiteApplicationExpScore: () =>
            import(
              './saseViews/sitesView/SitesApplicationExpScore/SitesAppExpScoreWrapper'
            ),
          DemSiteDetailsViewWrapper: () =>
            import('./saseViews/sitesView/SiteDetails/SiteDetailsViewWrapper'),
          DemApplicationDetailsViewWrapper: () =>
            import(
              './saseViews/components/AppDetailsExperienceView/AppDetailsExperienceViewWrapper'
            ),
          DemUserDetailsExpViewWrapper: () =>
            import(
              './saseViews/components/UserDetailsExperienceView/UserDetailsExperienceViewWrapper'
            ),
          ADEMLicenseAllocation: () =>
            import(
              './saseViews/AdemLicenseActivationView/AdemLicenseActivationView'
            ),
          ConnectedAccAppsSummaryWrapper: () =>
            import(
              './saseViews/acceleratedAppsView/components/ConnectedAccAppsSummary/ConnectedAccAppsSummaryWrapper'
            ),
          ConnectedDataAvgThroughputWrapper: () =>
            import(
              './saseViews/acceleratedAppsView/components/ConnectedAppImprovementSummary/ConnectedAppImprovementSummaryWrapper'
            ),
          NgfwSitesViewWrapper: () =>
            import('./saseViews/NgfwSitesView/NgfwSitesViewWrapper'),
          LastUpdatedWrapper: () =>
            import('./saseViews/lastUpdatedView/LastUpdatedWrapper'),
        },
      },
    };
  };
};
